import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/home/home";
import AboutUs from "./pages/about-us/about-us";
import History from "./pages/history/history";
import Order from "./pages/order/order";
import NotFound from "./pages/not-found/not-found";
import axios from "axios";
import ContactUs from "./pages/contact-us/contact-us";
import Profile from "./pages/profile/profile";
import { useState, useEffect } from "react";
import { SnackbarProvider } from "notistack";
export default function App() {
  const [userData, setUserData] = useState<any>();
  const [userDataLoading, setUserDataLoading] = useState(true);

  const getProfile = () => {
    setUserDataLoading(true);
    axios
      .get("/api/user")
      .then((res) => {
        console.log(res.data);
        setUserData(res.data);
        setUserDataLoading(false);
      })
      .catch((err) => {
        setUserDataLoading(false);
      });
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <Home userData={userData} userDataLoading={userDataLoading} />
            }
          />
          <Route path="about" element={<AboutUs />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="history" element={<History />} />
          <Route
            path="order"
            element={
              <Order userData={userData} userDataLoading={userDataLoading} />
            }
          />
          <Route
            path="profile"
            element={
              <Profile userData={userData} userDataLoading={userDataLoading} />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
