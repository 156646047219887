import styles from "./home.module.css";
import Navbar from "../../components/navbar/navbar";
import twitter from "../../images/twitter.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import testTruck from "../../images/truckTest.jpg";
import Lottie from "lottie-react";
import arrow from "../../animations/animation_lkob6rdg.json";
import { useEffect, useRef, useState } from "react";
import australiaPost from "../../images/australiaPost.png";
import startrack from "../../images/startrack.png";
import capital from "../../images/capital.png";
import jet from "../../images/jet.png";
import cito from "../../images/cito.webp";
import phoenix from "../../images/phoenix.png";
import dummy from "../../images/dummy.png";
import axios from "axios";
import box from "../../images/cardboardBox.jpg";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import universeLogo from "../../images/universeLogo.png";
import burgerMenu from "../../images/burgerMenu.png";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/login-modal/loginModal";
import SignUpModal from "../../components/signup-modal.tsx/signupModal";
import homeTruck from "../../images/homeTruck.png";
import homeVan from "../../images/homeVan.png";
import backToTop from "../../images/backToTop.png";
import rotate from "../../animations/rotatePhone.json";
import whiteTruck from "../../animations/whiteTruck.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img4 from "../../images/slider/4.jpg";
import img5 from "../../images/slider/5.jpg";
import img6 from "../../images/slider/6.jpeg";

import rightArrowIcon from "../../images/rightIcon.png";

interface IProps {
  userData: any;
  userDataLoading: boolean;
}
export default function Home({ userData, userDataLoading }: IProps) {
  const whoDiv = useRef<any>();
  const partnerDiv = useRef<any>();
  const [comments, setComments] = useState<any[]>([]);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [partnerVisible, setPartnerVisible] = useState(true);
  const [partnerLeftVisible, setPartnerLeftVisible] = useState(false);
  const [loadingComment, setLoadingComment] = useState(true);
  const sliderRef = useRef<any>();
  const sliderRefMob = useRef<any>();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const partnerToggleVisible = () => {
    const scrolled = sliderRef.current.scrollLeft;
    if (
      sliderRef.current.scrollWidth -
      sliderRef.current.offsetWidth -
      scrolled <=
      0
    ) {
      setPartnerVisible(false);
    } else {
      setPartnerVisible(true);
    }
    if (
      scrolled > 0
    ) {
      setPartnerLeftVisible(true);
    } else {
      setPartnerLeftVisible(false);
    }
  };
  const partnerToggleVisibleMob = () => {
    const scrolled = sliderRefMob.current.scrollLeft;
    if (
      sliderRefMob.current.scrollWidth -
      sliderRefMob.current.offsetWidth -
      scrolled <=
      0
    ) {
      setPartnerVisible(false);
    } else {
      setPartnerVisible(true);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener("scroll", partnerToggleVisible);
    }
    return () => {
      if (sliderRef.current)
        sliderRef.current.removeEventListener("scroll", partnerToggleVisible);
    };
  }, []);
  useEffect(() => {
    if (sliderRefMob.current) {
      sliderRefMob.current.addEventListener("scroll", partnerToggleVisibleMob);
    }
    return () => {
      if (sliderRefMob.current)
        sliderRefMob.current.removeEventListener(
          "scroll",
          partnerToggleVisibleMob
        );
    };
  }, []);

  const getComments = () => {
    setLoadingComment(true);
    axios
      .get("/api/comment")
      .then((res) => {
        setComments(res.data);
        setLoadingComment(false);
      })
      .catch((err) => {
        setLoadingComment(false);
      });
  };

  useEffect(() => {
    getComments();
  }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sModalIsOpen, setSModalIsOpen] = useState(false);
  function openModal() {
    setModalIsOpen(true);
  }
  function openSModal() {
    setSModalIsOpen(true);
  }
  const signOut = () => {
    axios
      .get("/api/user/signout")
      .then((res) => {
        setTimeout(() => {
          navigate(0);
        }, 1500);
      })
      .catch((err) => { });
  };

  return (
    <main>
      <div className={styles.preventLandScape}>
        <Lottie className={styles.rotate} animationData={rotate} />
      </div>
      <div className={styles.mainMob}>
        <div className={styles.backgroundMob}>
          <Slider autoplay arrows={undefined} className={styles.slider} autoplaySpeed={7000} speed={2000}>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `100svh`,
                  backgroundImage: `linear-gradient(rgba(0, 27, 255, 0.3), rgba(0, 100, 255, 0.3)),url(${box})`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `100svh`,
                  backgroundImage: `linear-gradient(rgba(0, 27, 255, 0.3), rgba(0, 100, 255, 0.3)),url(${img6})`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `100svh`,
                  backgroundImage: `linear-gradient(rgba(0, 27, 255, 0.3), rgba(0, 100, 255, 0.3)),url(${img5})`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </Slider>

          <div className={styles.menuDiv}>
            <Menu
              menuButton={
                <div className={styles.burgerDiv}>
                  <img
                    className={styles.burger}
                    src={burgerMenu}
                    alt="burgerMenu"
                  />
                </div>
              }
              transition
              menuClassName={styles.menuOpen}
            >
              <MenuItem
                onClick={() => navigate("/order")}
                className={styles.menuItem}
              >
                Order
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/history")}
                className={styles.menuItem}
              >
                History
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/about")}
                className={styles.menuItem}
              >
                About Us
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/contact")}
                className={styles.menuItem}
              >
                Contact Us
              </MenuItem>
              {userData ? (
                <>
                  <SubMenu
                    label={userData.firstName}
                    menuClassName={styles.menuOpen}
                  >
                    <MenuItem
                      onClick={() => navigate("/profile")}
                      className={styles.menuItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem onClick={signOut} className={styles.menuItem}>
                      Sign Out
                    </MenuItem>
                  </SubMenu>
                </>
              ) : (
                <>
                  <MenuItem onClick={openModal} className={styles.menuItem}>
                    Login
                  </MenuItem>
                  <MenuItem onClick={openSModal} className={styles.menuItem}>
                    Sign Up
                  </MenuItem>
                </>
              )}
            </Menu>
          </div>
          <div className={styles.mainContext}>
            <div className={styles.header}>
              <div className={styles.universeLogoDiv}>
                <img
                  className={styles.universeLogo}
                  src={universeLogo}
                  alt="logo"
                />
              </div>
            </div>
            <div className={styles.mobText}>
              Logistic Delivery Around The Victoria
              <button
                onClick={() => navigate("/order")}
                className={styles.mobOrderBtn}
              >
                Order Now
              </button>
            </div>
            <div
              onClick={() =>
                partnerDiv.current.scrollIntoView({ behavior: "smooth" })
              }
              className={styles.seeMore}
            >
              <p
                style={{
                  color: "white",
                  fontSize: "larger",
                  fontWeight: "bolder",
                }}
              >
                SEE MORE
              </p>
              <Lottie
                className={styles.arrow}
                animationData={arrow}
                sizes="24px"
              />
            </div>
          </div>
        </div>
        <LoginModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
        <SignUpModal
          modalIsOpen={sModalIsOpen}
          setModalIsOpen={setSModalIsOpen}
        />
      </div>
      <div ref={partnerDiv} className={styles.ourPartnersTextDiv}>
        <p className={styles.ourPartnersText}>Our Partners</p>
      </div>
      <div className={styles.partnerwraper} style={{ position: "relative" }}>
        <div
          className={`${styles.rightArrow} ${partnerVisible ? "" : styles.closed
            }`}
          style={{ pointerEvents: 'none' }}
        >
          <img
            style={{ width: 30, height: 30, objectFit: "contain" }}
            src={rightArrowIcon}
            alt="arrow icon"
          />
        </div>
        <div className={styles.ourPartnersCardDiv} ref={sliderRefMob}>
          <div className={styles.ourPartnersCard}>
            <div className={styles.ourPartnersCardImgDiv}>
              <img
                className={styles.ourPartnersCardImg}
                src={capital}
                alt="capitalLogo"
              />
            </div>
            <div className={styles.whatLinkDiv}>
              <a
                target="blank"
                className={styles.whatLinkText}
                href="https://capitaltransport.com.au/"
              >
                Capital Transport
              </a>
            </div>
          </div>
          <div className={styles.ourPartnersCard}>
            <div className={styles.ourPartnersCardImgDiv}>
              <img className={styles.ourPartnersCardImg} src={jet} alt="jet" />
            </div>
            <div className={styles.whatLinkDiv}>
              <a
                target="blank"
                className={styles.whatLinkText}
                href="https://jetcouriers.com.au/"
              >
                jet Transport
              </a>
            </div>
          </div>
          <div className={styles.ourPartnersCard}>
            <div className={styles.ourPartnersCardImgDiv}>
              <img
                className={styles.ourPartnersCardImg}
                src={phoenix}
                alt="phoenix"
              />
            </div>
            <div className={styles.whatLinkDiv}>
              <a
                target="blank"
                className={styles.whatLinkText}
                href="https://phoenixtransport.com.au/"
              >
                phoenix Transport
              </a>
            </div>
          </div>
          <div className={styles.ourPartnersCard}>
            <div className={styles.ourPartnersCardImgDiv}>
              <img
                className={styles.ourPartnersCardImg}
                src={cito}
                alt="cito"
              />
            </div>
            <div className={styles.whatLinkDiv}>
              <a
                target="blank"
                className={styles.whatLinkText}
                href="https://citotransport.com.au/"
              >
                Cito Transport
              </a>
            </div>
          </div>
          <div className={styles.ourPartnersCard}>
            <div className={styles.ourPartnersCardImgDiv}>
              <img
                className={styles.ourPartnersCardImg}
                src={australiaPost}
                alt="australiaPost"
              />
            </div>
            <div className={styles.whatLinkDiv}>
              <a
                target="blank"
                className={styles.whatLinkText}
                href="https://auspost.com.au/"
              >
                Australia Post
              </a>
            </div>
          </div>
          <div className={styles.ourPartnersCard}>
            <div className={styles.ourPartnersCardImgDiv}>
              <img
                className={styles.ourPartnersCardImg}
                src={startrack}
                alt="startrack"
              />
            </div>
            <div className={styles.whatLinkDiv}>
              <a
                target="blank"
                className={styles.whatLinkText}
                href="https://startrack.com.au"
              >
                Startrack
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.ourServicesTextDiv}>
        <p className={styles.ourServicesText}>Our Services</p>
      </div>
      <div className={styles.ourServicesCardDiv}>
        <div className={styles.ourServicesCard}>
          <img
            className={styles.ourServicesCardImg}
            src={homeTruck}
            alt="truck"
          />
          <p className={styles.ourServicesCardText}>Truck</p>
        </div>
        <div className={styles.ourServicesCard}>
          <img
            style={{ paddingTop: "10px" }}
            className={styles.ourServicesCardImg}
            src={homeVan}
            alt="van"
          />
          <p
            style={{ marginTop: "8px" }}
            className={styles.ourServicesCardText}
          >
            Van
          </p>
        </div>
      </div>
      <div className={styles.clientsSayTextDiv}>
        <p className={styles.clientsSayText}>What our Client says</p>
      </div>
      <div className={styles.ourClientsSayCardDiv}>
        {loadingComment ? (
          <div className={styles.loadingTruck}>
            <Lottie className={styles.whiteTruck} animationData={whiteTruck} />
          </div>
        ) : (
          comments.map((comment) => {
            return comment.enabled ? (
              <>
                <div key={comment.id} className={styles.ourClientsSayCard}>
                  <div className={styles.ourClientsSayCardImgDiv}>
                    <img
                      className={styles.ourClientsSayCardImg}
                      src={
                        comment.userId.profileUrl
                          ? "https://api.universetransport.com.au/" +
                            comment.userId.profileUrl
                          : dummy
                      }
                      alt="dummy"
                    />
                  </div>
                  <div className={styles.ourClientsSayCardTextDiv}>
                    <h4>
                      {comment.userId.firstName} {comment.userId.lastName}
                    </h4>
                    <h4>{comment.title}</h4>
                    <q
                      style={{
                        textJustify: "inter-word",
                        textAlign: "justify",
                        color: "white",
                      }}
                    >
                      {comment.text}
                    </q>
                  </div>
                </div>
              </>
            ) : (
              ""
            );
          })
        )}
      </div>
      <div className={styles.footerMob}>
        <div className={styles.innerFooterMob}>
          <div className={styles.footerMobLinks}>
            <div className={styles.footerMobLinksLeft}>
              <p onClick={() => navigate("/order")}>Order</p>
              <p onClick={() => navigate("/contact")}>Contact Us</p>
              <p onClick={() => navigate("/about")}>About Us</p>
              <p onClick={() => navigate("/history")}>History</p>
            </div>
            <div className={styles.footerMobLinksRight}>
              <Lottie
                className={styles.footerMobLinksRightAnimation}
                animationData={whiteTruck}
              />
            </div>
          </div>
          <p>
            © 2023 Universe Transprot. All rights reserved. Icons by{" "}
            <a
              className={styles.icon8}
              target="blank"
              href="https://icons8.com"
            >
              Icon8
            </a>
          </p>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.background}>
          <Slider autoplay arrows={undefined} className={styles.slider} autoplaySpeed={7000} speed={2000}>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `100vh`,
                  backgroundImage: `linear-gradient(rgba(0, 27, 255, 0.3), rgba(0, 100, 255, 0.3)),url(${img4})`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `100vh`,
                  backgroundImage: `linear-gradient(rgba(0, 27, 255, 0.3), rgba(0, 100, 255, 0.3)),url(${testTruck})`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `100vh`,
                  backgroundImage: `linear-gradient(rgba(0, 27, 255, 0.3), rgba(0, 100, 255, 0.3)),url(${img5})`,

                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </Slider>

          <div className={styles.maintext}>
            <div className={styles.nav}>
              <Navbar userDataLoading={userDataLoading} userData={userData} />
            </div>
            <div className={styles.maintextLeft}>
              <p className={styles.mainTextText}>
                Logistic Delivery
                <br /> Around The Victoria
              </p>
            </div>
            <div className={styles.maintextRight}>
              <button
                onClick={() => navigate("/order")}
                className={styles.orderBtn}
              >
                Order Now
              </button>
            </div>
            <div className={styles.shipping}>
              <div>SEE MORE</div>
              <div
                onClick={() =>
                  whoDiv.current.scrollIntoView({ behavior: "smooth" })
                }
                className={styles.arrow}
              >
                <Lottie animationData={arrow} sizes="24px" />
              </div>
            </div>
          </div>
        </div>
        <div ref={whoDiv} className={styles.who}>
          OUR PARTNERS
        </div>

        <div style={{ position: "relative" }}>
          <div
            className={`${styles.rightArrow} ${partnerVisible ? "" : styles.closed
              }`}
            onClick={() => sliderRef.current.scroll({ left: sliderRef.current.scrollLeft + 400, behavior: 'smooth' })}
          >
            <img
              style={{ width: 30, height: 30, objectFit: "contain" }}
              src={rightArrowIcon}
              alt="arrow icon"
            />
          </div>
          <div
            className={`${styles.leftArrow} ${partnerLeftVisible ? "" : styles.closed
              }`}
            onClick={() => sliderRef.current.scroll({ left: sliderRef.current.scrollLeft - 400, behavior: 'smooth' })}
          >
            <img
              style={{ width: 30, height: 30, objectFit: "contain" }}
              src={rightArrowIcon}
              alt="arrow icon"
            />
          </div>
          <div className={styles.what} ref={sliderRef}>
            <div className={styles.whatItem}>
              <div className={styles.whatImgDiv}>
                <img
                  className={styles.whatImg}
                  src={capital}
                  alt="capitallogo"
                />
              </div>
              <div className={styles.whatLinkDiv}>
                <a
                  target="blank"
                  className={styles.whatLinkText}
                  href="https://capitaltransport.com.au/"
                >
                  Capital Transport
                </a>
              </div>
            </div>
            <div className={styles.whatItem}>
              <div className={styles.whatImgDiv}>
                <img className={styles.whatImg} src={jet} alt="jetlogo" />
              </div>
              <div className={styles.whatLinkDiv}>
                <a
                  target="blank"
                  className={styles.whatLinkText}
                  href="https://www.jetcouriers.com.au/"
                >
                  Jet Couriers
                </a>
              </div>
            </div>
            <div className={styles.whatItem}>
              <div className={styles.whatImgDiv}>
                <img
                  className={styles.whatImg}
                  src={phoenix}
                  alt="phoenixlogo"
                />
              </div>
              <div className={styles.whatLinkDiv}>
                <a
                  target="blank"
                  className={styles.whatLinkText}
                  href="https://phoenixtransport.com.au/"
                >
                  Phoenix Transport
                </a>
              </div>
            </div>
            <div className={styles.whatItem}>
              <div className={styles.whatImgDiv}>
                <img className={styles.whatImg} src={cito} alt="citologo" />
              </div>
              <div className={styles.whatLinkDiv}>
                <a
                  target="blank"
                  className={styles.whatLinkText}
                  href="https://citotransport.com.au/"
                >
                  Cito Transport
                </a>
              </div>
            </div>
            <div className={styles.whatItem}>
              <div className={styles.whatImgDiv}>
                <img
                  className={styles.whatImg}
                  src={australiaPost}
                  alt="australiaPost"
                />
              </div>
              <div className={styles.whatLinkDiv}>
                <a
                  target="blank"
                  className={styles.whatLinkText}
                  href="https://auspost.com.au"
                >
                  Australia Post
                </a>
              </div>
            </div>
            <div className={styles.whatItem}>
              <div className={styles.whatImgDiv}>
                <img
                  className={styles.whatImg}
                  src={startrack}
                  alt="startrack"
                />
              </div>
              <div className={styles.whatLinkDiv}>
                <a
                  target="blank"
                  className={styles.whatLinkText}
                  href="https://startrack.com.au"
                >
                  Startrack
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.howText}>
          <p className={styles.howText}>Our Services</p>
        </div>
        <div className={styles.how}>
          <div className={styles.howItem}>
            <div className={styles.howItemTextDiv}>
              <p className={styles.howItemText}>Truck</p>
            </div>
            <div className={styles.howItemImgDiv}>
              <img className={styles.howItemImg} src={homeTruck} alt="" />
            </div>
          </div>
          <div className={styles.howItem}>
            <div className={styles.howItemImgDiv}>
              <img
                style={{ marginTop: "0px" }}
                className={styles.howItemImg}
                src={homeVan}
                alt=""
              />
            </div>
            <div className={styles.howItemTextDiv}>
              <p className={styles.howItemText}>Van</p>
            </div>
          </div>
        </div>
        <div className={styles.comment}>
          <div className={styles.commentTextDiv}>
            <p className={styles.commentText}>What our Clients say</p>
          </div>

          <div className={styles.commentCardSection}>
            {loadingComment ? (
              <div className={styles.loadingTruck}>
                <Lottie
                  className={styles.whiteTruck}
                  animationData={whiteTruck}
                />
              </div>
            ) : (
              comments.map((comment) => {
                return comment.enabled ? (
                  <>
                    <div key={comment.id} className={styles.commentCard}>
                      <img
                        className={styles.commentImg}
                        src={
                          comment.userId.profileUrl
                            ? "https://api.universetransport.com.au/" +
                              comment.userId.profileUrl
                            : dummy
                        }
                        alt="dummy"
                      ></img>
                      <p>{comment.userId.firstName}</p>
                      <p>{comment.title}</p>
                      <q className={styles.userComment}>{comment.text}</q>
                    </div>
                  </>
                ) : (
                  ""
                );
              })
            )}
          </div>
        </div>
        <div className={styles.footerDivider}>
          <div className={styles.innerFooterDivider}></div>
        </div>
        <div className={styles.footer}>
          <div className={styles.innerFooter}>
            {" "}
            <div className={styles.footerMain}>
              <p>Universe Transport</p>
              <div className={styles.footerIcons}>
                <img className={styles.icons} src={facebook} alt="" />
                <img className={styles.icons} src={twitter} alt="" />
                <img className={styles.icons} src={instagram} alt="" />
              </div>
              <p>
                © 2023 Universe Transprot. All rights reserved. Icons by{" "}
                <a
                  className={styles.icon8}
                  target="blank"
                  href="https://icons8.com"
                >
                  Icon8
                </a>
              </p>
            </div>
            <div className={styles.rightFooter}>
              <div className={styles.footerLinks}>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/order")}
                >
                  Order
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/contact")}
                >
                  Contact Us
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/about")}
                >
                  About Us
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/history")}
                >
                  History
                </p>
              </div>
              <div className={styles.footerAnimationDiv}>
                <Lottie
                  className={styles.footerAnimation}
                  animationData={whiteTruck}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: visible ? "" : "none" }} className={styles.top}>
        <img
          onClick={scrollToTop}
          className={styles.topImg}
          src={backToTop}
          alt="topbtn"
        />
      </div>
    </main>
  );
}
