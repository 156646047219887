import styles from "./about-us.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../../images/slider/1.jpg";
import img2 from "../../images/slider/2.jpg";
import img3 from "../../images/slider/3.jpg";
import backToHome from "../../images/backToHome.png";
import { useNavigate } from "react-router-dom";
import backToHomeLight from "../../images/backToHomeLight.png";
import Lottie from "lottie-react";
import rotate from "../../animations/rotatePhone.json";

export default function AboutUs() {
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <main className={styles.container}>
      <div className={styles.preventLandScape}>
        <Lottie className={styles.rotate} animationData={rotate} />
      </div>
      <div className={styles.construction}>
        <p>Currently not Available!</p>

        <p style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          back to Home!
        </p>
      </div>
      {/* <div onClick={()=>navigate('/')} className={styles.backToHomeDiv}>
        <img className={styles.backToHome} src={backToHomeLight} alt="backtohome" />
      </div>
      <div className={styles.top}>
        <div className={styles.aboutUsTextDiv}>
          <div className={styles.backToHome}>
            <img
              onClick={() => navigate("/")}
              className={styles.backToHomeImg}
              src={backToHome}
              alt=""
            />
          </div>
          <p
            style={{
              color: "#0b2447",
              fontSize: "larger",
              fontWeight: "bolder",
            }}
          >
            About Us
          </p>
        </div>
        <div className={styles.aboutUsText}>
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia, looked up one of the more obscure
          Latin words, consectetur, from a Lorem Ipsum passage, and going
          through the cites of the word in classical literature, discovered the
          undoubtable source. Lorem Ipsum comes from sections 1.10.32 and
          1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and
          Evil) by Cicero, written in 45 BC. This book is a treatise on the
          theory of ethics, very popular during the Renaissance. The first line
          of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
          section 1.10.32. The standard chunk of Lorem Ipsum used since the
          1500s is reproduced below for those interested. Sections 1.10.32 and
          1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
          reproduced in their exact original form, accompanied by English
          versions from the 1914 translation by H. Rackham. Contrary to popular
          belief, Lorem Ipsum is not simply random text. It has roots in a piece
          of classical Latin literature from 45 BC, making it over 2000 years
          old. Richard McClintock, a Latin professor at Hampden-Sydney College
          in Virginia, looked up one of the more obscure Latin words,
          consectetur, from a Lorem Ipsum passage, and going through the cites
          of the word in classical literature, discovered the undoubtable
          source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
          Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
          written in 45 BC. This book is a treatise on the theory of ethics,
          very popular during the Renaissance. The first line of Lorem Ipsum,
          "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
          The standard chunk of Lorem Ipsum used since the 1500s is reproduced
          below for those interested. Sections 1.10.32 and 1.10.33 from "de
          Finibus Bonorum et Malorum" by Cicero are also reproduced in their
          exact original form, accompanied by English versions from the 1914
          translation by H. Rackham.
        </div>
      </div>
      <div className={styles.bottom}>
        <Slider
          autoplay
          arrows={undefined}
          className={styles.slider}
          {...settings}
        >
          
          <div>
            <div
              style={{
                width: "100%",
                height: `30svh`,
                backgroundImage: `url(${img1})`,
                borderRadius: 20,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                width: "100%",
                height: `30svh`,
                backgroundImage: `url(${img2})`,
                borderRadius: 20,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                width: "100%",
                height: `30svh`,
                backgroundImage: `url(${img3})`,
                borderRadius: 20,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </Slider>
      </div>
      <div className={styles.left}>
        <div className={styles.innerLeft}>
          <Slider
            autoplay
            arrows={undefined}
            className={styles.slider}
            {...settings}
          >
            
            <div>
              <div
                style={{
                  width: "100%",
                  height: `90vh`,
                  backgroundImage: `url(${img1})`,
                  borderRadius: 20,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `90vh`,
                  backgroundImage: `url(${img2})`,
                  borderRadius: 20,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <div>
              <div
                style={{
                  width: "100%",
                  height: `90vh`,
                  backgroundImage: `url(${img3})`,
                  borderRadius: 20,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </Slider>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.innerRight}>About us</div>
      </div> */}
    </main>
  );
}
