import styles from "./profile.module.css";
import profileLogout from "../../images/profileLogout.png";
import dummy from "../../images/dummy.png";
import profileIcon from "../../images/profile.png";
import call from "../../images/loginCall.png";
import mail from "../../images/mail.png";
import image from "../../images/image.png";
import { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import locationIcon from "../../images/location.png";
import orderCalender from "../../images/Ordercalender.png";
import orderWeight from "../../images/orderWeight.png";
import orderCar from "../../images/orderCar.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import locked from "../../animations/locked.json";
import { Icon } from "leaflet";
import profileNavy from "../../images/profileNavy.png";
import orderNavy from "../../images/orderNavy.png";
import commentNavy from "../../images/commentNavy.png";
import backToHome from "../../images/backToHome.png";
import { enqueueSnackbar } from "notistack";
import OrderModal from "../../components/order-modal/orderModal";
import commentLight from "../../images/commentLightBlue.png";
import whiteTruck from "../../animations/whiteTruck.json";

interface IProps {
  userData: any;
  userDataLoading: boolean;
}
export default function Profile({ userData, userDataLoading }: IProps) {
  const navigate = useNavigate();
  const [commentLoading, setCommentLoading] = useState(true);
  const [orderLoading, setOrderLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<
    "profile" | "order" | "comment"
  >("profile");
  const [userOrder, setUserOrder] = useState<any[]>([]);
  const [firstName, setFirstName] = useState("");
  const [userId, setUserId] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [imageURL, setImageURL] = useState<any>(undefined);
  const [userComments, setUserComments] = useState<any[]>([]);
  const [updateBtn, setUpdateBtn] = useState(false);
  function openModal() {
    setModalIsOpen(true);
  }

  const markerIcon = new Icon({
    iconUrl: locationIcon,
    iconSize: [40, 40],
  });

  const handleOpenDetail = (detail: any) => {
    setSelectedOrder(detail);
    setModalIsOpen(true);
  };

  useEffect(() => {
    if (userData) {
      setUserId(userData._id);

      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setPhoneNumber(userData.phoneNumber);
      setProfileUrl(userData.profileUrl);
      getOrder();
    }
  }, [userData]);
  const getComments = () => {
    setCommentLoading(true);
    axios
      .get("/api/comment/usercomment")
      .then((res) => {
        setCommentLoading(false);
        setUserComments(res.data);
      })
      .catch((err) => {
        setCommentLoading(false);
      });
  };

  const getOrder = () => {
    if (userData) {
      setOrderLoading(true);
      axios
        .get(`/api/order/${userData._id}`)
        .then((res) => {
          setOrderLoading(false);

          setUserOrder(res.data);
        })
        .catch((err) => {
          setOrderLoading(false);
        });
    }
  };
  useEffect(() => {
    getComments();
  }, []);

  const signOut = () => {
    axios
      .get("/api/user/signout")
      .then((res) => {
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {});
  };
  const handleImageUpload = (e: any) => {
    let image = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageURL(reader.result);
    };
    reader.readAsDataURL(image);
  };
  const handleUpdate = (e: any) => {
    setUpdateBtn(true);
    e.preventDefault();

    axios
      .post("/api/user/update", {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        profileUrl: imageURL,
      })
      .then((res) => {
        enqueueSnackbar("successful", { variant: "success" });
        setUpdateBtn(false);
        setTimeout(() => {
          navigate(0);
        }, 3000);
      })
      .catch((err) => {
        setUpdateBtn(false);
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };
  return (
    <main className={styles.container}>
      {userDataLoading ? (
        <div className={styles.loading}>
          <Lottie className={styles.whiteTruck} animationData={whiteTruck} />
        </div>
      ) : userData ? (
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.innerLeft}>
              <div className={styles.iconsMob}>
                <div onClick={() => setSelectedTab("profile")}>
                  <img
                    className={styles.profileMob}
                    src={profileNavy}
                    alt="profile"
                  />
                </div>
                <div onClick={() => setSelectedTab("order")}>
                  <img
                    className={styles.profileMob}
                    src={orderNavy}
                    alt="order"
                  />
                </div>
                <div onClick={() => setSelectedTab("comment")}>
                  <img
                    className={styles.profileMob}
                    src={commentNavy}
                    alt="comment"
                  />
                </div>
                <div className={styles.profileLogoutDiv}>
                  <img
                    className={styles.profileMob}
                    src={backToHome}
                    alt="home"
                    onClick={() => navigate("/")}
                    style={{ width: "40px" }}
                  />
                </div>
                <div className={styles.profileLogoutDiv}>
                  <img
                    className={styles.profileLogout}
                    src={profileLogout}
                    alt="logout"
                    onClick={signOut}
                  />
                </div>
              </div>

              <div className={styles.userName}>
                <div className={styles.profileLogoutDiv}>
                  <img
                    className={styles.profileLogout}
                    src={profileLogout}
                    alt="logout"
                    onClick={signOut}
                  />
                </div>
                <div className={styles.profilePicDiv}>
                  <img
                    className={styles.profilePic}
                    src={
                      userData.profileUrl
                        ? "https://api.universetransport.com.au/" +
                          userData.profileUrl
                        : dummy
                    }
                    alt="dummy "
                  />
                </div>
                <p className={styles.userNameText}>
                  {userData ? (
                    <>
                      {userData.firstName} {userData.lastName}
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div
                onClick={() => setSelectedTab("profile")}
                className={styles.profile}
              >
                <p className={styles.profileText}>Profile</p>
              </div>

              <div
                onClick={() => setSelectedTab("order")}
                className={styles.profile}
              >
                <p className={styles.profileText}>Orders</p>
              </div>
              <div
                onClick={() => setSelectedTab("comment")}
                className={styles.profile}
              >
                <p className={styles.profileText}>Comments</p>
              </div>
              <div onClick={() => navigate("/")} className={styles.profile}>
                <p className={styles.profileText}>Home</p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.innerRight}>
              {selectedTab === "profile" ? (
                <div className={styles.innerRightDiv}>
                  <img
                    className={styles.innerRightPic}
                    src={
                      userData.profileUrl
                        ? "https://api.universetransport.com.au/" +
                          userData.profileUrl
                        : dummy
                    }
                    alt="dummy"
                  />
                  <form className={styles.form}>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={image}
                        alt="profile"
                      ></img>
                      <input
                        style={{ paddingTop: "15px", paddingLeft: "10px" }}
                        id="name-input"
                        type="file"
                        placeholder="First Name"
                        className={styles.input}
                        name="myImage"
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={profileIcon}
                        alt="profile"
                      ></img>
                      <input
                        id="name-input"
                        type="text"
                        placeholder="First Name"
                        className={styles.input}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={profileIcon}
                        alt="profile"
                      ></img>
                      <input
                        id="name-input"
                        type="text"
                        placeholder="Last Name"
                        className={styles.input}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={call}
                        alt="profile"
                      ></img>
                      <input
                        id="name-input"
                        type="text"
                        placeholder="Phone Number"
                        className={styles.input}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={mail}
                        alt="profile"
                      ></img>
                      <input
                        id="name-input"
                        type="text"
                        placeholder="Email"
                        className={styles.input}
                        defaultValue={userData ? userData.email : ""}
                        disabled
                      />
                    </div>
                    <button
                      disabled={updateBtn}
                      className={styles.btn}
                      id="login-button"
                      type="submit"
                      onClick={handleUpdate}
                    >
                      {updateBtn ? "Please Wait..." : "Update"}
                    </button>
                  </form>
                </div>
              ) : selectedTab === "order" ? (
                orderLoading ? (
                  <div className={styles.orderDiv}>Loading...</div>
                ) : (
                  <div className={styles.orderDiv}>
                    {userOrder.map((orderMap) => {
                      return (
                        <div className={styles.orderCard}>
                          <div className={styles.orderStatus}>
                            <p className={styles.orderStatusText}>
                              {orderMap.status}
                            </p>
                            {orderMap.status === "Finished" ? (
                              <img
                                onClick={() => handleOpenDetail(orderMap)}
                                className={styles.commentLight}
                                src={commentLight}
                                alt="comment"
                              ></img>
                            ) : (
                              ""
                            )}
                          </div>
                          <MapContainer
                            style={{
                              width: "100%",
                              height: "80%",
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                            }}
                            center={[-37.81062501722524, 144.9899202468286]}
                            zoom={10}
                            scrollWheelZoom={true}
                          >
                            <Marker
                              draggable={false}
                              position={[
                                orderMap.fromLocation.lat,
                                orderMap.fromLocation.long,
                              ]}
                              icon={markerIcon}
                            >
                              <Popup>
                                <>
                                  {orderMap.fromLocation.lat} <br />{" "}
                                  {orderMap.fromLocation.lat}{" "}
                                </>
                              </Popup>
                            </Marker>
                            <Marker
                              draggable={false}
                              position={[
                                orderMap.toLocation.lat,
                                orderMap.toLocation.long,
                              ]}
                              icon={markerIcon}
                            >
                              <Popup>
                                <>
                                  {orderMap.toLocation.lat} <br />{" "}
                                  {orderMap.toLocation.lat}{" "}
                                </>
                              </Popup>
                            </Marker>
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </MapContainer>
                          <div className={styles.orderDetail}>
                            <div className={styles.orderSingleDetail}>
                              <div className={styles.orderIcontext}>
                                <img
                                  className={styles.orderIcon}
                                  src={orderCalender}
                                  alt="location"
                                />
                                <p className={styles.orderDetailName}>Date</p>
                              </div>
                              <p className={styles.orderData}>
                                {orderMap.dateOfService
                                  ? new Date(
                                      orderMap.dateOfService
                                    ).toLocaleString()
                                  : ""}
                              </p>
                            </div>
                            <div className={styles.orderSingleDetail}>
                              <div className={styles.orderIcontext}>
                                <img
                                  className={styles.orderIcon}
                                  src={orderWeight}
                                  alt="location"
                                />
                                <p className={styles.orderDetailName}>Weight</p>
                              </div>
                              <p className={styles.orderData}>
                                {orderMap.typeOfWeight ? (
                                  <>{orderMap.weight} KG</>
                                ) : (
                                  <>{orderMap.pallete} Pallete</>
                                )}
                              </p>
                            </div>
                            <div className={styles.orderSingleDetail}>
                              <div className={styles.orderIcontext}>
                                <img
                                  className={styles.orderIcon}
                                  src={orderCar}
                                  alt="location"
                                />
                                <p className={styles.orderDetailName}>Car</p>
                              </div>
                              <p className={styles.orderData}>
                                {orderMap.carId.carName}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              ) : selectedTab === "comment" ? (
                commentLoading ? (
                  <div className={styles.loading}>Loading...</div>
                ) : (
                  <div className={styles.commentDiv}>
                    {userComments.map((commentMap) => {
                      return commentMap.enabled ? (
                        <>
                          <div
                            key={commentMap.id}
                            className={styles.commentCard}
                          >
                            <p>{commentMap.title}</p>
                            <q className={styles.userComment}>
                              {commentMap.text}
                            </q>
                            <p>Order Id: {commentMap.orderId}</p>
                          </div>
                        </>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.loginMode}>
          <Lottie className={styles.lockedAnimation} animationData={locked} />
          <h2>you must login first!</h2>
          <button onClick={() => navigate("/")} className={styles.homeBtn}>
            back To Home
          </button>
        </div>
      )}
      <OrderModal
        detail={selectedOrder}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        userData={userData}
      />
    </main>
  );
}
