import styles from "./contact-us.module.css";
import Lottie from "lottie-react";
import message from "../../animations/message.json";
import profile from "../../images/profile.png";
import mail from "../../images/mail.png";
import call from "../../images/call.png";
import email from "../../images/email.png";
import location from "../../images/location.png";
import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import instagram from "../../images/instagram.png";
import quoteSent from "../../animations/quoteSent.json";
import { useState } from "react";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import backToHomeLight from "../../images/backToHomeLight.png";
import { useNavigate } from "react-router-dom";
import rotate from "../../animations/rotatePhone.json";

export default function AboutUs() {
  const [userName, setUserName] = useState("");
  const [userMail, setUserMail] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [sentSuccess, setSentSuccess] = useState(true);
  const navigate = useNavigate();
  const handleContact = (e: any) => {
    e.preventDefault();
    axios
      .post("/api/quote/", {
        name: userName,
        email: userMail,
        message: userMessage,
      })
      .then((res) => {
        setSentSuccess(false);
        enqueueSnackbar("successful", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };
  return (
    <main className={styles.container}>
      <div className={styles.preventLandScape}>
        <Lottie className={styles.rotate} animationData={rotate} />
      </div>
      <div className={styles.left}>
        <div className={styles.backToHome}>
          <img
            onClick={() => navigate("/")}
            className={styles.backToHomeImg}
            src={backToHomeLight}
            alt="home"
          />
        </div>
        <h1 className={styles.title}>Get a Quote</h1>
        <p className={styles.leftText}>
          Fill up the form and our Team will get back to you within 24 hours
        </p>
        <div>
          {sentSuccess ? (
            <div className={styles.formMob}>
              <Lottie className={styles.lottieMob} animationData={message} />
              <form className={styles.from}>
                <div className={styles.inputComponent}>
                  <img
                    className={styles.icons}
                    src={profile}
                    alt="profile"
                  ></img>
                  <input
                    id="name-input"
                    type="text"
                    placeholder="Your Name"
                    className={styles.input}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className={styles.inputComponent}>
                  <img className={styles.icons} src={mail} alt="profile"></img>
                  <input
                    id="email-input"
                    type="email"
                    placeholder="Email"
                    className={styles.input}
                    value={userMail}
                    onChange={(e) => setUserMail(e.target.value)}
                  />
                </div>
                <textarea
                  id="message-input"
                  placeholder="Message"
                  className={styles.inputMessage}
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  aria-multiline
                  style={{
                    wordBreak: "break-all",
                    wordWrap: "break-word",
                    resize: "none",
                    paddingRight: "10px",
                  }}
                />
                <button
                  className={styles.btn}
                  id="login-button"
                  type="submit"
                  onClick={handleContact}
                >
                  Send
                </button>
              </form>
            </div>
          ) : (
            <div className={styles.quoteSent}>
              <Lottie animationData={quoteSent} />
            </div>
          )}
        </div>

        <div className={styles.info}>
          <div className={styles.call}>
            <div className={styles.callIcon}>
              <img
                style={{ width: "30px", height: "30px" }}
                className={styles.icons}
                src={call}
                alt="profile"
              />
            </div>

            <p style={{ marginLeft: "10px" }}>0491 966 663</p>
          </div>
          <div className={styles.call}>
            <div className={styles.callIcon}>
              {" "}
              <img
                style={{ width: "30px", height: "30px" }}
                className={styles.icons}
                src={email}
                alt="profile"
              />
            </div>

            <p style={{ marginLeft: "10px" }}>Jahan@universeleader.com.au</p>
          </div>
          <div className={styles.address}>
            <div className={styles.callIcon}>
              <img
                style={{ width: "30px", height: "30px" }}
                className={styles.icons}
                src={location}
                alt="profile"
              />
            </div>

            <p style={{ marginLeft: "10px" }}>Office 9, 585 Burwood Hwy Knoxfield Victoria 3180</p>
          </div>
        </div>
        <div className={styles.logos}>
          <div className={styles.logosImg}>
            <img className={styles.iconItself} src={facebook} alt="facebook" />
          </div>
          <div className={styles.logosImg}>
            <img className={styles.iconItself} src={twitter} alt="facebook" />
          </div>
          <div className={styles.logosImg}>
            <img className={styles.iconItself} src={instagram} alt="facebook" />
          </div>
        </div>
      </div>
      <div className={styles.right}>
        {sentSuccess ? (
          <div className={styles.contact}>
            <Lottie animationData={message} />
            <form className={styles.from}>
              <div className={styles.inputComponent}>
                <img className={styles.icons} src={profile} alt="profile"></img>
                <input
                  id="name-input"
                  type="text"
                  placeholder="Your Name"
                  className={styles.input}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className={styles.inputComponent}>
                <img
                  style={{ marginRight: "10px" }}
                  className={styles.icons}
                  src={mail}
                  alt="profile"
                ></img>
                <input
                  id="email-input"
                  type="email"
                  placeholder="Email"
                  className={styles.input}
                  value={userMail}
                  onChange={(e) => setUserMail(e.target.value)}
                />
              </div>
              <textarea
                id="message-input"
                placeholder="Message"
                className={styles.inputMessage}
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                style={{
                  wordBreak: "break-all",
                  wordWrap: "break-word",
                  resize: "none",
                  paddingRight: "10px",
                }}
              />
              <button
                className={styles.btn}
                id="login-button"
                type="submit"
                onClick={handleContact}
              >
                Send
              </button>
            </form>
          </div>
        ) : (
          <Lottie animationData={quoteSent} />
        )}
      </div>
    </main>
  );
}
