/* eslint-disable no-lone-blocks */
import styles from "./order.module.css";
import done from "../../images/done.png";
import { useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import Lottie from "lottie-react";
import truck from "../../images/truck.png";
import van from "../../images/van.png";
import weight from "../../images/weight.png";
import pallete from "../../images/pallete.png";
import weightRadio from "../../images/weightRadio.png";
import palleteRadio from "../../images/palleteRadio.png";
import calender from "../../images/calender.png";
import DatePicker from "react-datepicker";
import orderSuccess from "../../animations/orderSuccess.json";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import locked from "../../animations/locked.json";
import { enqueueSnackbar } from "notistack";
import pin from "../../images/location.png";
import startingPin from "../../images/locationStarting.png";
import { Icon } from "leaflet";
import rotate from "../../animations/rotatePhone.json";
import whiteTruck from "../../animations/whiteTruck.json";
import addressIcon from "../../images/addressIcon.png";

interface IProps {
  userData: any;
  userDataLoading: boolean;
}

export default function Order({ userData, userDataLoading }: IProps) {
  const markerIcon = new Icon({
    iconUrl: pin,
    iconSize: [40, 40],
  });
  const markerIconStarting = new Icon({
    iconUrl: startingPin,
    iconSize: [40, 40],
  });
  const center = {
    lat: -37.81062501722524,
    lng: 144.9899202468286,
  };
  const markerRef = useRef<any | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [weightType, setWeightType] = useState(true);
  const steps = [1, 2, 3, "tick"];
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [position, setPosition] = useState(center);
  const [toPosition, setToPosition] = useState(center);
  const [weightInput, setWeightInput] = useState<any>("");
  const [car, setCar] = useState("6530d78137274526381d63aa");
  // const [car, setCar] = useState("64ad5bbb708ff0742cc5b061");
  const [fromLine1, setFromLine1] = useState("");
  const [fromLine2, setFromLine2] = useState("");
  const [fromSuburb, setFromSuburb] = useState("");
  const [fromPostCode, setFromPostCode] = useState("");
  const [toLine1, setToLine1] = useState("");
  const [toLine2, setToLine2] = useState("");
  const [toSuburb, setToSuburb] = useState("");
  const [toPostCode, setToPostCode] = useState("");

  const handleOrder = (e: any) => {
    e.preventDefault();
    {
      weightType
        ? axios
            .post("/api/order", {
              fromLat: position.lat,
              fromLong: position.lng,
              toLat: toPosition.lat,
              toLong: toPosition.lng,
              dateOfService: startDate,
              carId: car,
              typeOfWeight: weightType,
              weight: weightInput,
              fromline1: fromLine1,
              fromline2: fromLine2,
              fromsuburb: fromSuburb,
              fromPostcode: fromPostCode,
              toline1: toLine1,
              toline2: toLine2,
              tosuburb: toSuburb,
              toPostcode: toPostCode,
            })
            .then((res) => {
              enqueueSnackbar("success", { variant: "success" });

              forward();
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, { variant: "error" });
            })
        : axios
            .post("/api/order", {
              fromLat: position.lat,
              fromLong: position.lng,
              toLat: toPosition.lat,
              toLong: toPosition.lng,
              dateOfService: startDate,
              carId: car,
              typeOfWeight: weightType,
              pallete: weightInput,
              fromline1: fromLine1,
              fromline2: fromLine2,
              fromsuburb: fromSuburb,
              fromPostcode: fromPostCode,
              toline1: toLine1,
              toline2: toLine2,
              tosuburb: toSuburb,
              toPostcode: toPostCode,
            })
            .then((res) => {
              enqueueSnackbar("success", { variant: "success" });
              forward();
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, { variant: "error" });
            });
    }
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    [position]
  );
  const eventHandlersTo = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setToPosition(marker.getLatLng());
        }
      },
    }),
    [toPosition]
  );
  const navigate = useNavigate();
  const forward = () => {
    setSelectedIndex((value) => value + 1);
  };
  const backward = () => {
    setSelectedIndex((value) => value - 1);
  };
  const selectKG = () => {
    setWeightType(true);
  };
  const selectPlt = () => {
    setWeightType(false);
  };
  const selectTruck = () => {
    setCar("6530d78137274526381d63aa");
  };
  const selectVan = () => {
    setCar("6530d74537274526381d63a8");
  };

  return (
    <main className={styles.container}>
      <div className={styles.preventLandScape}>
        <Lottie className={styles.rotate} animationData={rotate} />
      </div>
      {userDataLoading ? (
        <div className={styles.loading}>
          <Lottie className={styles.whiteTruck} animationData={whiteTruck} />
        </div>
      ) : userData ? (
        <div className={styles.main}>
          <div className={styles.bar}>
            {steps.map((element, index) => {
              return (
                <>
                  <div
                    className={`${styles.part1} ${
                      selectedIndex > index ? styles.active : ""
                    }`}
                  >
                    {index === steps.length - 1 ? (
                      <img className={styles.done} src={done} alt="done" />
                    ) : (
                      <p className={styles.num}>{element}</p>
                    )}
                  </div>
                  {index < steps.length - 1 && (
                    <div className={styles.line}></div>
                  )}
                </>
              );
            })}
          </div>
          {selectedIndex === 1 ? (
            <>
              <div className={styles.leveltextDiv}>
                <p className={styles.leveltext}>
                  Please choose the Starting Point
                </p>
              </div>
              <div className={styles.bottom}>
                <MapContainer
                  // style={{ width: "100%", height: "100%" }}
                  key={"jhgjhjkh"}
                  className={styles.map}
                  center={[-37.81062501722524, 144.9899202468286]}
                  zoom={13}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    draggable
                    eventHandlers={eventHandlers}
                    position={position}
                    ref={markerRef}
                    icon={markerIcon}
                  />
                </MapContainer>
              </div>
            </>
          ) : selectedIndex === 2 ? (
            <>
              <div className={styles.leveltextDiv}>
                <p className={styles.leveltext}>
                  Please choose the Destination
                </p>
              </div>
              <div className={styles.bottom}>
                <MapContainer
                  key={"klsajdfklj"}
                  // style={{ width: "100%", height: "67vh" }}
                  className={styles.map}
                  center={[-37.81062501722524, 144.9899202468286]}
                  zoom={13}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    eventHandlers={eventHandlers}
                    position={position}
                    ref={markerRef}
                    icon={markerIconStarting}
                  />
                  <Marker
                    draggable
                    eventHandlers={eventHandlersTo}
                    position={toPosition}
                    ref={markerRef}
                    icon={markerIcon}
                  />
                </MapContainer>
              </div>
            </>
          ) : selectedIndex === 3 ? (
            <>
              <div className={styles.leveltextDiv}>
                <p className={styles.leveltext3}>
                  Please select the weight, Date and the car you need
                </p>
              </div>
              <div className={styles.formContainer}>
                <form className={styles.form}>
                  <div className={styles.cardSection}>
                    <label
                      className={
                        weightType ? styles.carCardSelected : styles.carCard
                      }
                    >
                      <input
                        type="radio"
                        name="dark"
                        id="dark"
                        value="dark"
                        onClick={selectKG}
                        defaultChecked={true}
                      />
                      KG
                      <div className={styles.topCardImg}>
                        <img
                          style={{ width: "50px" }}
                          src={weightRadio}
                          alt="truck"
                        />
                      </div>
                    </label>

                    <label
                      className={
                        !weightType ? styles.carCardSelected : styles.carCard
                      }
                    >
                      <input
                        type="radio"
                        name="dark"
                        id="dark"
                        value="dark"
                        onClick={selectPlt}
                      />
                      Pallete
                      <div className={styles.topCardImg}>
                        <img
                          style={{ width: "50px" }}
                          src={palleteRadio}
                          alt="van"
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.inputComponent}>
                    {weightType ? (
                      <>
                        <img
                          className={styles.icons}
                          src={weight}
                          alt="profile"
                        />
                        <input
                          id="name-input"
                          type="number"
                          inputMode="numeric"
                          placeholder="Weight"
                          className={styles.input}
                          value={weightInput}
                          onChange={(e) => setWeightInput(e.target.value)}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className={styles.icons}
                          src={pallete}
                          alt="profile"
                        />
                        <input
                          id="name-input"
                          type="number"
                          inputMode="numeric"
                          placeholder="Pallete"
                          className={styles.input}
                          value={weightInput}
                          onChange={(e) => setWeightInput(e.target.value)}
                        />
                      </>
                    )}
                  </div>
                  <div className={styles.dateComponent}>
                    <img
                      className={styles.icons}
                      src={calender}
                      alt="profile"
                    />
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      className={styles.datePicker}
                    />
                  </div>
                  <div className={styles.cardSection}>
                    <label
                      className={
                        car === "64ad5bbb708ff0742cc5b061"
                          ? styles.carCardSelected
                          : styles.carCard
                      }
                    >
                      <input
                        type="radio"
                        name="car"
                        id="car"
                        value="car"
                        onClick={selectTruck}
                        defaultChecked={true}
                      />
                      Truck
                      <div className={styles.cardImg}>
                        <img
                          style={{ width: "150px" }}
                          src={truck}
                          alt="truck"
                        />
                      </div>
                    </label>

                    <label
                      className={
                        car === "64ad5c3454af3d8e87a82d74"
                          ? styles.carCardSelected
                          : styles.carCard
                      }
                    >
                      <input
                        type="radio"
                        name="car"
                        id="car"
                        value="car"
                        onClick={selectVan}
                      />
                      Van
                      <div className={styles.cardImg}>
                        <img style={{ width: "150px" }} src={van} alt="van" />
                      </div>
                    </label>
                  </div>
                  <div className={styles.addresses}>
                    please insert your from address
                    <br />
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromLine1"
                        type="string"
                        placeholder="Address Line 1"
                        className={styles.input}
                        value={fromLine1}
                        onChange={(e) => setFromLine1(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromLine2"
                        type="string"
                        placeholder="Address Line 2"
                        className={styles.input}
                        value={fromLine2}
                        onChange={(e) => setFromLine2(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromsuburb"
                        type="string"
                        placeholder="Suburb"
                        className={styles.input}
                        value={fromSuburb}
                        onChange={(e) => setFromSuburb(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromPostCode"
                        type="number"
                        inputMode="numeric"
                        placeholder="Post Code"
                        className={styles.input}
                        value={fromPostCode}
                        onChange={(e) => setFromPostCode(e.target.value)}
                      />
                    </div>
                    <br />
                    please insert your Destination address
                    <br />
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromLine1"
                        type="string"
                        placeholder="Address Line 1"
                        className={styles.input}
                        value={toLine1}
                        onChange={(e) => setToLine1(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromLine2"
                        type="string"
                        placeholder="Address Line 2"
                        className={styles.input}
                        value={toLine2}
                        onChange={(e) => setToLine2(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromsuburb"
                        type="string"
                        placeholder="Suburb"
                        className={styles.input}
                        value={toSuburb}
                        onChange={(e) => setToSuburb(e.target.value)}
                      />
                    </div>
                    <div className={styles.inputComponent}>
                      <img
                        className={styles.icons}
                        src={addressIcon}
                        alt="profile"
                      />
                      <input
                        id="fromPostCode"
                        type="number"
                        inputMode="numeric"
                        placeholder="Post Code"
                        className={styles.input}
                        value={toPostCode}
                        onChange={(e) => setToPostCode(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <p className={styles.orderSuccessText}>Order Successful</p>
              <div className={styles.orderSuccess}>
                <Lottie animationData={orderSuccess} />
              </div>
              <p className={styles.orderSuccessText2}>We will be in touch with you less than 24 hours</p>
            </>
          )}
          <div className={styles.pt1Bottom}>
            {selectedIndex === 1 ? (
              <div className={styles.btnDiv}>
                <button
                  onClick={() => navigate("/")}
                  className={styles.pt1btnBack}
                >
                  Back to home
                </button>
                <button onClick={forward} className={styles.pt1btn}>
                  Next
                </button>
              </div>
            ) : selectedIndex === steps.length ? (
              <button
                onClick={() => navigate("/")}
                className={styles.pt1btnLast}
              >
                Back To Home
              </button>
            ) : selectedIndex === 3 ? (
              <div className={styles.btnDiv}>
                <button onClick={backward} className={styles.pt1btnBack}>
                  Back
                </button>
                <button onClick={handleOrder} className={styles.pt1btn}>
                  Confirm Order
                </button>
              </div>
            ) : (
              <div className={styles.btnDiv}>
                <button onClick={backward} className={styles.pt1btnBack}>
                  Back
                </button>
                <button onClick={forward} className={styles.pt1btn}>
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.loginMode}>
          <Lottie className={styles.lockedAnimation} animationData={locked} />
          <h2>you have to login first!</h2>
          <button onClick={() => navigate("/")} className={styles.homeBtn}>
            back To Home
          </button>
        </div>
      )}
    </main>
  );
}
