import styles from "./navbar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import LoginModal from "../login-modal/loginModal";
import SignUpModal from "../signup-modal.tsx/signupModal";
import { useState } from "react";
import axios from "axios";
import { Menu, MenuItem } from "@szhsin/react-menu";
import universeLogo from "../../images/universeLogo.png";

interface IProps {
  userData: any;
  userDataLoading: boolean;
}
export default function Navbar({ userData, userDataLoading }: IProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sModalIsOpen, setSModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }
  function openSModal() {
    setSModalIsOpen(true);
  }
  const navigate = useNavigate();
  const signOut = () => {
    axios
      .get("/api/user/signout")
      .then((res) => {
        setTimeout(() => {
          navigate(0);
          window.location.reload()
        }, 1500);
      })
      .catch((err) => {});
  };
  return (
    <main className={styles.nav}>
      <nav className={styles.main}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={styles.navlinkLogo}>
            <NavLink className={styles.navlinkText} to="/">
              <img
                className={styles.universeLogo}
                src={universeLogo}
                alt="universeLogo"
              />
            </NavLink>
          </div>
          <div className={styles.navlink}>
            <NavLink className={styles.navlinkText} to="/order">
              Order
            </NavLink>
          </div>
          <div className={styles.navlink}>
            <NavLink className={styles.navlinkText} to="/history">
              History
            </NavLink>
          </div>
          <div className={styles.navlink}>
            <NavLink className={styles.navlinkText} to="/about">
              About Us
            </NavLink>
          </div>
          <div className={styles.navlink}>
            <NavLink className={styles.navlinkText} to="/contact">
              Contact Us
            </NavLink>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {userDataLoading ? (
            <div>Loading...</div>
          ) : userData ? (
            <div className={styles.navlinkLogin}>
              <Menu
                menuButton={
                  <div className={styles.navlinkText}>{userData.firstName}</div>
                }
                transition
                menuClassName={styles.menuOpen}
                align="center"
              >
                <MenuItem
                  onClick={() => navigate("/profile")}
                  className={styles.menuItem}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={signOut} className={styles.menuItem}>
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <>
              <div className={styles.navlinkLogin} onClick={openModal}>
                Log in{" "}
              </div>
              <div className={styles.navlinkLogin} onClick={openSModal}>
                sign up
              </div>
            </>
          )}
        </div>
      </nav>
      <LoginModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <SignUpModal
        modalIsOpen={sModalIsOpen}
        setModalIsOpen={setSModalIsOpen}
      />
    </main>
  );
}
