import styles from "./not-found.module.css";
import notFound from "../../animations/notFound.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <main className={styles.container}>
      <div className={styles.main}>
        <div className={styles.lottie}>
          <Lottie animationData={notFound} />
        </div>
        <div>
          <button
            onClick={() => navigate("/")}
            type="submit"
            className={styles.btn}
          >
            back to Home
          </button>
        </div>
      </div>
    </main>
  );
}
