import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./orderModal.module.css";
import Modal from "react-modal";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
  detail: any;
  userData: any;
}

export default function OrderModal({
  modalIsOpen,
  setModalIsOpen,
  detail,
  userData,
}: IProps) {
  const [send, setSend] = useState(false);
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [userId, setUserId] = useState("");
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState("");
  function closeModal() {
    setModalIsOpen(false);
  }
  useEffect(() => {
    if (detail) {
      setOrderId(detail._id);
      setOrderStatus(detail.status);
    }
  }, [detail]);
  useEffect(() => {
    if (userData) {
      setUserId(userData._id);
    }
  }, [userData]);
  const handleComment = (e: any) => {
    setSend(true);
    e.preventDefault();
    axios
      .post("/api/comment", {
        orderId: orderId,
        title: title,
        text: comment,
      })
      .then((res) => {
        setSend(false);
        closeModal();
        enqueueSnackbar("successful", { variant: "success" });
        setTimeout(() => {
          navigate(0);
        }, 1500);
      })
      .catch((err) => {
        setSend(false);
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={styles.content}
      contentLabel="Example Modal"
      ariaHideApp={false}
      style={{ overlay: { zIndex: 100000 } }}
    >
      <div className={styles.container}>
        <div className={styles.top}>
          <p className={styles.topText}>{orderId}</p>
        </div>

        <div className={styles.bottom}>
          {orderStatus === "Finished" ? (
            <>
              <form className={styles.from}>
                <div className={styles.inputComponent}>
                  <input
                    id="name-input"
                    type="text"
                    placeholder="Title"
                    className={styles.input}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className={styles.inputComponent}>
                  <textarea
                    id="name-input"
                    placeholder="Comment"
                    className={styles.Commentinput}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{
                      wordBreak: "break-all",
                      wordWrap: "break-word",
                      resize: "none",
                      paddingRight: "10px",
                    }}
                  />
                </div>

                <button
                  disabled={send}
                  onClick={handleComment}
                  className={styles.btn}
                  id="login-button"
                  type="submit"
                >
                 {send ? "Please Wait ..." : "Send Comment"} 
                </button>
              </form>
            </>
          ) : (
            <>This is order is not in the Finished situation!</>
          )}
        </div>
      </div>
    </Modal>
  );
}

